// src/pages/preview.js

import React, {useEffect} from 'react'
import { withPreviewResolver, usePrismicPreview } from 'gatsby-source-prismic'

import linkResolver from '../utils/linkResolver'
import { navigate, useStaticQuery, graphql } from 'gatsby'

import AmazeePage from '../components/layout';

const PreviewPage = (data) => {
  // Let's use a static query to retrieve all known paths. We'll use it later
  // to navigate to the unpublishedPreview page if the document is not
  // published.
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `)
  const allPaths = allSitePage.nodes.map((node) => node.path)

  const {previewData, path, isPreview} = usePrismicPreview({
    repositoryName: 'amazeeio',
    linkResolver: () => linkResolver,
  });

  // This useEffect runs when values from usePrismicPreview update. When
  // preview data is available, this will save the data globally and redirect to
  // the previewed document's page.
  useEffect(() => {
    // If this is not a preview, skip.
    //   null = Not yet determined if previewing.
    //   true = Preview is available.
    //   false = Preview is not available.
    if (isPreview === false || !previewData) return

    // Save the preview data to somewhere globally accessible. This could be
    // something like a global Redux store or React context.
    //
    // We'll just put it on window.
    window.__PRISMIC_PREVIEW_DATA__ = previewData

    // Navigate to the document's page if page exists.
    if (allPaths.includes(path)) {
      console.log('Path exists', path);
      navigate(path)
    } else {
      console.log('Path does not exist, going to unpublished-preview', path);
      navigate('/unpublished-preview')
    }
  }, [isPreview, previewData, path])

  if (isPreview === false) return 'Not a preview!'

  return (
    <AmazeePage location={data.location}>
      <p>Loading</p>
    </AmazeePage>
  );
}

export default PreviewPage;